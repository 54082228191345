import { FC, useState, useEffect } from "react";
import cx from "classnames";
import { Skeleton } from "@/components/Loader";
import Button from "@/components/Button";
import Menu, { MenuItem } from "@/components/Menu";
import { ListItemText } from "@/components/List";
import { ChevronIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import { useTrackers } from "@/hooks";
import styles from "./MobileContentGuide.module.scss";

type Props = {
  items: Array<{ title: string; id: string }>;
  isLoading: boolean;
  resourceId: number;
};

type Item = {
  title: string;
  id: string;
};

const StepNumber: FC<{ number: number; isSelected?: boolean }> = ({
  number,
  isSelected,
}) => {
  return (
    <div
      className={cx(styles.numberContainer, {
        [styles.selectedNumberContainer]: isSelected,
      })}
    >
      <Typography variant="subtitle2" color="text.contrast">
        {number}
      </Typography>
    </div>
  );
};

const MobileContentGuide: FC<Props> = ({ items, isLoading, resourceId }) => {
  const [selectedItem, setSelectedItem] = useState<undefined | Item>(items[0]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const selectedItemIndex = items.findIndex(
    (each) => each.id === selectedItem?.id
  );

  const isMenuOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { track } = useTrackers();

  useEffect(() => {
    const detectItem = () => {
      const elementItems = items.map((each) => ({
        ...each,
        el: document.getElementById(each.id),
      }));
      const currentSelectedItem = elementItems
        .sort(
          (first, second) =>
            (first.el?.clientTop || 0) - (second.el?.clientTop || 0)
        )
        .filter(
          (each) =>
            (each.el?.getBoundingClientRect().top || 0) > 70 &&
            (each.el?.getBoundingClientRect().bottom || 0) < window.innerHeight
        )[0];
      if (currentSelectedItem) {
        setSelectedItem(currentSelectedItem);
      }
    };

    window.addEventListener("scroll", detectItem);
    return () => {
      window.removeEventListener("scroll", detectItem);
    };
  }, [items]);

  useEffect(() => {
    if (items.length === 0) return;
    setSelectedItem(items[0]);
  }, [items]);

  const goOnStep = (stepId: string) => {
    const currentStep = items.find((each) => each.id === stepId);
    track("Resource Step Selected", {
      eventId: "resource-step-selected",
      resourceId: resourceId,
      step: currentStep?.title,
    });
    const element = document.getElementById(stepId);
    window.scrollTo(window.scrollX, (element?.offsetTop || 0) - 184);
  };

  return (
    <div className={styles.root}>
      {isLoading ? (
        <Skeleton variant="text" className={styles.title} />
      ) : (
        <>
          {items.length > 0 && (
            <Typography
              color="text.secondary"
              variant="subtitle1"
              className={styles.title}
            >
              Steps
            </Typography>
          )}
        </>
      )}
      {isLoading ? (
        <Skeleton
          width="80px"
          height="46px"
          variant="rectangular"
          className={styles.buttonLoader}
        />
      ) : (
        <>
          <Button
            variant="text"
            color="info"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className={styles.button}
          >
            <StepNumber number={(selectedItemIndex || 0) + 1} isSelected />
            {selectedItem?.title}
            <ChevronIcon dir="down" fontSize="inherit" />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleClose}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            classes={{ paper: styles.menu }}
          >
            {items.map((eachItem, idx) => (
              <MenuItem
                selected={selectedItem?.id === eachItem.id}
                key={eachItem.id}
                onClick={() => {
                  goOnStep(eachItem.id);
                  handleClose();
                }}
              >
                <StepNumber
                  number={idx + 1}
                  isSelected={eachItem.id === selectedItem?.id}
                />
                <ListItemText aria-selected="true" primary={eachItem.title} />
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};

export default MobileContentGuide;

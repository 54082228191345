import { FC, useState, useMemo } from "react";
// @ts-ignore
import { Image } from "cloudinary-react";
import { useRouter } from "next/router";
import Typography from "@/components/Typography";
import { ChevronIcon } from "@/components/Icon";
import { Resource, RESOURCE_CATEGORIES } from "@/fetch/gworld";
import { ResourcePdfViewer } from "@/components/PdfViewer";
import { useFeatureFlags, useLock, useTrackers } from "@/hooks";
import { ClickableCard } from "@/components/Card";
import {
  ResourceGuideIconV1,
  ResourceGuideIconV2,
  ResourceGuideIconV3,
  ResourceGuideIconV4,
  ResourcePostArrivalIcon,
  ResourceVisaIcon,
} from "@/components/Icon";
import LockedDialog from "./LockedDialog";
import styles from "./ResourceItem.module.scss";

type Props = {
  resource?: Resource;
  type: RESOURCE_CATEGORIES;
  index: number;
};

const ResourceItem: FC<Props> = ({ resource, type, index }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLockDialogOpen, setIsLockDialogOpen] = useState<boolean>(false);

  const {
    flags: { resourceArrivalLocked, resourcePaymentLocked, resourceTimeLocked },
  } = useFeatureFlags();

  const { push } = useRouter();
  const { track } = useTrackers();

  const { isSuperliteTrip } = useLock();

  const resourceId = resource?.resources?.[0]?.id;

  const isLocked =
    ((type === RESOURCE_CATEGORIES.Visa && resourcePaymentLocked) ||
      (type === RESOURCE_CATEGORIES.PostArrivalDocuments &&
        resourceArrivalLocked) ||
      (type === RESOURCE_CATEGORIES.Guides && resourceTimeLocked)) &&
    !isSuperliteTrip;

  const openResource = () => {
    if (isLocked) {
      setIsLockDialogOpen(true);
    } else {
      if (Boolean(resource?.htmlContent)) {
        track("Resource Item Card Clicked", {
          eventId: "resource-item-card-clicked",
          resourceId: resource?.id,
          resourceType: type,
        });
        push(`/experience/resources/${resource?.id}`);
      } else if (resourceId) {
        setIsDialogOpen(true);
      }
    }
  };

  const iconColor: Array<"primary" | "secondary" | "tertiary"> = [
    "primary",
    "secondary",
    "tertiary",
  ];

  // randomly assign guide icon
  const GuideIcon = useMemo(() => {
    const randomNumber = Math.floor(Math.random() * 100);
    if (randomNumber < 25)
      return (
        <ResourceGuideIconV1 className={styles.icon} width={64} height={64} />
      );
    if (randomNumber < 50)
      return (
        <ResourceGuideIconV2 className={styles.icon} width={64} height={64} />
      );
    if (randomNumber < 75)
      return (
        <ResourceGuideIconV3 className={styles.icon} width={64} height={64} />
      );
    return (
      <ResourceGuideIconV4 className={styles.icon} width={64} height={64} />
    );
  }, []);

  return (
    <>
      <ClickableCard className={styles.container} onClick={openResource}>
        {type === RESOURCE_CATEGORIES.PostArrivalDocuments ? (
          <ResourcePostArrivalIcon
            className={styles.icon}
            width={64}
            height={64}
          />
        ) : type === RESOURCE_CATEGORIES.Visa ? (
          <ResourceVisaIcon className={styles.icon} width={64} height={64} />
        ) : (
          GuideIcon
        )}
        <Typography variant="body1" className={styles.title}>
          {resource?.title}{" "}
        </Typography>
        <ChevronIcon dir="right" className={styles.button} />
      </ClickableCard>
      {isDialogOpen && (
        <ResourcePdfViewer
          onClose={() => setIsDialogOpen(false)}
          resourceId={resourceId || 0}
          title={resource?.title || ""}
          isDownloadable={type === RESOURCE_CATEGORIES.PDG}
        />
      )}

      {isLockDialogOpen && (
        <LockedDialog
          onClose={() => setIsLockDialogOpen(false)}
          variant={
            type === RESOURCE_CATEGORIES.Visa
              ? "payment"
              : type === RESOURCE_CATEGORIES.PostArrivalDocuments
              ? "arrival"
              : "time"
          }
        />
      )}
    </>
  );
};

export default ResourceItem;

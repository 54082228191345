import { FC, useState, useRef, useEffect } from "react";
import cx from "classnames";
import { ChevronIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import { useTrackers } from "@/hooks";
import styles from "./FAQ.module.scss";

type Props = {
  question: string;
  answer: string;
  faqId?: number;
};

const FAQ: FC<Props> = ({ question, answer, faqId }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const answerRef = useRef<HTMLDivElement | null>(null);
  const questionRef = useRef<HTMLDivElement | null>(null);

  const { track } = useTrackers();

  const answerHeight = answerRef.current?.offsetHeight || 0;
  const questionHeight = (questionRef.current?.offsetHeight || 24) + 40;

  useEffect(() => {
    if (!isExpanded) return;
    track("FAQ Viewed", {
      eventId: "faq-viewed",
      faqId: faqId,
    });
  }, [isExpanded]);

  return (
    <div
      role="presentation"
      onClick={() => setIsExpanded(!isExpanded)}
      className={cx(styles.card, { [styles.expandedCard]: isExpanded })}
      style={{
        height: isExpanded ? questionHeight + answerHeight : questionHeight,
      }}
    >
      <div className={styles.topContainer} ref={questionRef}>
        <Typography variant="body1">{question}</Typography>
        <ChevronIcon
          className={cx(styles.icon, { [styles.expandedIcon]: isExpanded })}
        />
      </div>
      <div ref={answerRef}>
        <Typography
          variant="body2"
          color="text.secondary"
          className={cx({ [styles.hiddenAnswer]: !isExpanded })}
        >
          {answer}
        </Typography>
      </div>
    </div>
  );
};

export default FAQ;

import { FC } from "react";
// @ts-ignore
import { Image } from "cloudinary-react";
import cx from "classnames";
import Typography from "@/components/Typography";
import { RedirectIcon } from "@/components/Icon";
import { useOpenExternalURL, useTrackers } from "@/hooks";
import { Blog } from "@/fetch/gworld";
import styles from "./BlogCard.module.scss";

type Props = {
  blog?: Blog;
  className?: string;
};

const BlogCard: FC<Props> = ({ blog, className }) => {
  const openExternalLink = useOpenExternalURL();
  const { track } = useTrackers();

  const handleOpenLink = () => {
    track("Blog Link Opened", {
      eventId: "blog-link-opened",
      blogLink: blog?.marketing?.url,
    });
    openExternalLink(blog?.marketing?.url || "");
  };

  return (
    <div className={cx(styles.container, className)}>
      {/*change to cloudinary before going live, just for testing */}
      {/* <Image
        alt="banner"
        cloudName="gwat-dev" // change to gwatco before going live
        publicId={blog?.thumbnail}
        className={styles.image}
      /> */}
      {/*eslint-disable-next-line @next/next/no-img-element*/}
      <img
        alt="blog_photo"
        src={blog?.thumbnail}
        className={styles.image}
        style={{ objectFit: "cover" }}
      />
      <div className={styles.content}>
        <RedirectIcon className={styles.icon} onClick={handleOpenLink} />
        {/* <Typography
          variant="caption"
          color="text.contrast"
          className={styles.label}
        >
          Travel advice
        </Typography> */}
        <Typography variant="h6" color="text.contrast">
          {blog?.title || ""}
        </Typography>
        <Typography variant="h6" color="text.contrast">
          {blog?.description || ""}
        </Typography>
      </div>
    </div>
  );
};

export default BlogCard;

import { FC, useEffect } from "react";
import { useRouter } from "next/router";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import styles from "./LockedDialog.module.scss";
import { useTrackers } from "@/hooks";

type Props = { onClose: () => void; variant: "payment" | "time" | "arrival" };

const LockedDialog: FC<Props> = ({ onClose, variant }) => {
  const { track } = useTrackers();

  const { push } = useRouter();

  useEffect(() => {
    track("Resource Locked Dialog Opened", {
      eventId: "resource-locked-dialog-opened",
    });
  }, []);

  const handleClose = () => {
    track("Resource Locked Dialog Left", {
      eventId: "resource-locked-dialog-left",
    });
    if (variant === "payment") {
      push("/experience/payments/invoice/trip");
    } else {
      onClose();
    }
  };

  const dialogTitle = {
    payment: "Unlocking soon!",
    time: "Unlocking soon!",
    arrival: "Unlocking soon!",
  };

  const lockImages = {
    payment: "/images/resource-payment-lock.svg",
    time: "/images/resource-time-lock.svg",
    arrival: "/images/resource-time-lock.svg",
  };

  const lockTitle = {
    payment:
      "Complete your payment to unlock access to this important document.",
    time: "Unlocking soon!",
    arrival: "Unlocking soon! ",
  };

  const lockDescription = {
    payment:
      "Once your trip is paid in full, you’ll gain access to essential information for your upcoming trip.",
    time: "As your arrival date approaches, you'll gain access to important documents for your upcoming trip.",
    arrival:
      "Access this document upon arrival for essential information to help settle into your new destination.",
  };

  const buttonText = {
    payment: "Proceed to Payment",
    time: "Great",
    arrival: "Great",
  };

  return (
    <Dialog open>
      <DialogTitle onClose={onClose}>{dialogTitle[variant]}</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={lockImages[variant]} alt="icon" className={styles.icon} />
        <Typography variant="h6" className={styles.title}>
          {lockTitle[variant]}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          className={styles.description}
        >
          {lockDescription[variant]}
        </Typography>
      </DialogContent>
      <DialogActions className={styles.actionContainer}>
        <Button onClick={handleClose} className={styles.cancelButton}>
          {buttonText[variant]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LockedDialog;

import { FC, useState } from "react";
import { useRouter } from "next/router";
import Typography from "@/components/Typography";
import { ClickableCard } from "@/components/Card";
import Button from "@/components/Button";
import { ArrowIcon } from "@/components/Icon";
import { ResourcePdfViewer } from "@/components/PdfViewer";
import { Resource, RESOURCE_CATEGORIES } from "@/fetch/gworld";
import { useFeatureFlags, useLock, useTrackers } from "@/hooks";
import LockedDialog from "./LockedDialog";
import styles from "./ResourceCard.module.scss";

type Props = {
  resource?: Resource;
  type: RESOURCE_CATEGORIES;
};

const ResourceCard: FC<Props> = ({ resource, type }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLockDialogOpen, setIsLockDialogOpen] = useState<boolean>(false);

  const { push } = useRouter();

  const {
    flags: { resourcePaymentLocked, resourceArrivalLocked, resourceTimeLocked },
  } = useFeatureFlags();
  const { isSuperliteTrip } = useLock();
  const { track } = useTrackers();

  const resourceId = resource?.resources?.[0]?.id;

  const isLocked =
    ((type === RESOURCE_CATEGORIES.Visa && resourcePaymentLocked) ||
      (type === RESOURCE_CATEGORIES.PDG && resourcePaymentLocked) ||
      (type === RESOURCE_CATEGORIES.PostArrivalDocuments &&
        resourceArrivalLocked) ||
      (type === RESOURCE_CATEGORIES.Guides && resourceTimeLocked)) &&
    !isSuperliteTrip;

  const openResource = () => {
    if (isLocked) {
      setIsLockDialogOpen(true);
    } else {
      track("Resource Main Card Clicked", {
        eventId: "resource-main-card-clicked",
        resourceId: resource?.id,
        resourceType: type,
      });
      if (Boolean(resource?.htmlContent)) {
        push(`/experience/resources/${resource?.id}`);
      } else if (resourceId) {
        setIsDialogOpen(true);
      }
    }
  };

  return (
    <>
      <ClickableCard className={styles.container} onClick={openResource}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="https://gwatco-res.cloudinary.com/image/upload/v1719903835/gwat.gworld/assets/pdg-cover.jpg"
          alt="banner"
          className={styles.image}
        />
        <div className={styles.content}>
          <Typography variant="h6">{resource?.title}</Typography>
          <Typography variant="body2" className={styles.description}>
            {resource?.description}
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary"
            className={styles.buttonText}
          >
            Read now
            <ArrowIcon dir="right" className={styles.arrowIcon} />
          </Typography>
        </div>
      </ClickableCard>
      {isDialogOpen && (
        <ResourcePdfViewer
          onClose={() => setIsDialogOpen(false)}
          resourceId={resourceId || 0}
          title={resource?.title || ""}
          isDownloadable={type === RESOURCE_CATEGORIES.PDG}
        />
      )}

      {isLockDialogOpen && (
        <LockedDialog
          onClose={() => setIsLockDialogOpen(false)}
          variant={
            type === RESOURCE_CATEGORIES.Visa ||
            type === RESOURCE_CATEGORIES.PDG
              ? "payment"
              : type === RESOURCE_CATEGORIES.PostArrivalDocuments
              ? "arrival"
              : "time"
          }
        />
      )}
    </>
  );
};

export default ResourceCard;

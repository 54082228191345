import { FC, useEffect, useState } from "react";
import Link from "next/link";
import cx from "classnames";
import { useRouter } from "next/router";
import Typography from "@/components/Typography";
import Stepper, { Step, StepLabel } from "@/components/Stepper";
import { Skeleton } from "@/components/Loader";
import { useTrackers } from "@/hooks";
import styles from "./ContentGuide.module.scss";

type Props = {
  items: Array<{ title: string; id: string }>;
  isLoading: boolean;
  resourceId: number;
};

type Item = {
  title: string;
  id: string;
};

const ContentGuide: FC<Props> = ({ items, isLoading, resourceId }) => {
  const [selectedItem, setSelectedItem] = useState<undefined | Item>(items[0]);

  const router = useRouter();
  const { track } = useTrackers();

  useEffect(() => {
    const detectItem = () => {
      const elementItems = items.map((each) => ({
        ...each,
        el: document.getElementById(each.id),
      }));
      const currentSelectedItem = elementItems
        .sort(
          (first, second) =>
            (first.el?.clientTop || 0) - (second.el?.clientTop || 0)
        )
        .filter(
          (each) =>
            (each.el?.getBoundingClientRect().top || 0) > 70 &&
            (each.el?.getBoundingClientRect().bottom || 0) < window.innerHeight
        )[0];
      if (currentSelectedItem) {
        setSelectedItem(currentSelectedItem);
      }
    };

    window.addEventListener("scroll", detectItem);
    return () => {
      window.removeEventListener("scroll", detectItem);
    };
  }, [items]);

  useEffect(() => {
    if (items.length === 0) return;
    setSelectedItem(items[0]);
  }, [items]);

  const selectedItemIndex = items.findIndex(
    (each) => each.id === selectedItem?.id
  );

  const goOnStep = (stepId: string) => {
    const currentStep = items.find((each) => each.id === stepId);
    track("Resource Step Selected", {
      eventId: "resource-step-selected",
      resourceId: resourceId,
      step: currentStep?.title,
    });
    const element = document.getElementById(stepId);
    window.scrollTo(window.scrollX, (element?.offsetTop || 0) - 100);
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Skeleton variant="text" className={styles.title} />
      ) : (
        <>
          {items.length > 0 && (
            <Typography
              color="text.secondary"
              variant="subtitle1"
              className={styles.title}
            >
              Steps
            </Typography>
          )}
        </>
      )}
      {isLoading ? (
        <>
          <Skeleton variant="text" className={styles.stepLoader} />
          <Skeleton variant="text" className={styles.stepLoader} />
          <Skeleton variant="text" className={styles.stepLoader} />
        </>
      ) : (
        <Stepper
          nonLinear
          activeStep={selectedItemIndex || 0}
          orientation="vertical"
        >
          {items.map((each, index) => (
            <Step
              key={each.id}
              className={styles.step}
              onClick={() => goOnStep(each.id)}
            >
              <StepLabel className={styles.stepLabel}>{each.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </div>
  );
};

export default ContentGuide;

import { FC, useState, ChangeEvent, useEffect } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Dialog, {
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@/components/Dialog";
import Button from "@/components/Button";
import Typography from "@/components/Typography";
import TextField from "@/components/TextField";
import { useCreateFaqMutation } from "@/fetch/gworld";
import styles from "./SubmitQuestionDialog.module.scss";
import { useTrackers, useSnackbar } from "@/hooks";

type Props = { onClose: () => void };

const SubmitQuestionDialog: FC<Props> = ({ onClose }) => {
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [question, setQuestion] = useState<string>("");
  const { track } = useTrackers();

  const { mutateAsync: createFaq, isLoading: isCreatingFaq } =
    useCreateFaqMutation();

  const { enqueueSnackbar } = useSnackbar();
  const { push } = useRouter();

  const onSubmit = async () => {
    if (!question) return;
    try {
      await createFaq({ question });
      setShowSuccess(true);
      track("FAQ Question Submitted", {
        eventId: "faq-question-submitted",
        question: question,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Sorry, something went wrong!", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    track("FAQ question dialog opened", {
      eventId: "faq-question-dialog-opened",
    });
  }, []);

  const goToDashboard = () => {
    onClose();
  };

  return (
    <Dialog open classes={{ paper: styles.paper }}>
      <DialogTitle onClose={onClose}>Submit a question</DialogTitle>
      {showSuccess ? (
        <>
          <DialogContent classes={{ root: styles.dialogSuccessContent }}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={`/images/submit-question-success.svg`}
              alt="icon"
              className={styles.successImage}
            />
            <Typography variant="h6" className={styles.successTitle}>
              You have successfully submitted your question.
            </Typography>
            <Typography
              color="text.secondary"
              variant="subtitle1"
              className={styles.successDescription}
            >
              Thank you for reaching out! Our team is reviewing your question
              and will respond to you shortly.
            </Typography>
            <Button className={styles.successButton} onClick={goToDashboard}>
              Go to FAQs
            </Button>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent className={styles.dialogContent}>
            <Image
              alt="help"
              src={`https://gwatco-res.cloudinary.com/image/upload/c_fill,g_auto,dpr_2.0,w_636,h_180,ar_2:1/gwat.gworld/assets/help.jpg`}
              objectFit="cover"
              width={636}
              height={180}
              className={styles.image}
            />
            <div className={styles.content}>
              <Typography variant="body1">Ask us anything</Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className={styles.description}
              >
                Have a question that hasn&apos;t been addressed in our FAQ?
                We&apos;re here to help! Submit your question below, and our
                team will provide you with a personalized response as soon as
                possible
              </Typography>
              <TextField
                value={question}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setQuestion(event.target.value)
                }
                fullWidth
                placeholder="Type your question here..."
                className={styles.textField}
              />
            </div>
          </DialogContent>
          <DialogActions className={styles.actionContainer}>
            <Button
              onClick={onSubmit}
              className={styles.cancelButton}
              disabled={isCreatingFaq}
            >
              Submit
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SubmitQuestionDialog;

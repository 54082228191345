import { useEffect, useState } from "react";

const useResourceContentGuideHelper = (contentRef: any, content: string) => {
  const [guides, setGuides] = useState<Array<{ title: string; id: string }>>(
    []
  );
  const [isLoadingGuides, setIsLoadingGuides] = useState<boolean>(true);

  const loadItems = () => {
    const currentContent = contentRef?.current as HTMLDivElement;

    if (!currentContent) return;

    const allElements = currentContent.querySelectorAll("*");
    allElements.forEach((eachEl: any) => {
      eachEl.style.fontFamily = "ProximaNova"; // apply gWorld font
      const color1 = "#00BBE3"; // website color
      const color1Small = "#00bbe3";
      const color1Rgb = "rgb(0, 187, 227)";
      const color2 = "#00BCE3"; //website color
      const color2Small = "#00bce3";
      const color2Rgb = "rgb(0, 188, 227)";
      const gWorldColor = "#0095cd";
      if (
        eachEl.style.color === color1 ||
        eachEl.style.color === color1Small ||
        eachEl.style.color === color1Rgb ||
        eachEl.style.color === color2 ||
        eachEl.style.color === color2Small ||
        eachEl.style.color === color2Rgb
      ) {
        eachEl.style.color = gWorldColor; // change website color with gWorld color
      }
    });

    const sections = currentContent.querySelectorAll(
      '[element_type="section"]'
    );
    const newVisaGuides: Array<{ title: string; id: string }> = [];
    const linkTags = Array.from(currentContent.getElementsByTagName("a"));
    linkTags.forEach((item: any) => {
      item.target = "_blink";
    });
    sections.forEach((item: any, idx: any) => {
      const sectionName = item.textContent || "";

      item.id = `Section-${idx + 1}`;
      newVisaGuides.push({ title: sectionName, id: `Section-${idx + 1}` });
    });
    setGuides(newVisaGuides);
    setIsLoadingGuides(false);
  };

  useEffect(() => {
    setTimeout(() => {
      loadItems();
    }, 1000);
  }, [content]);

  return { guides, isLoadingGuides };
};

export default useResourceContentGuideHelper;

import { FC, useState } from "react";
import { Document, pdfjs } from "react-pdf";
import Button from "@/components/Button";
import Divider from "@/components/Divider";
import { DownloadIcon, ArrowIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import { useTrackers } from "@/hooks";
import { GeneralPdfViewer } from "@/components/PdfViewer";
import styles from "./FileItem.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  title?: string;
  pdfLink?: string;
};

const FileItem: FC<Props> = ({ title, pdfLink }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };

  const { track } = useTrackers();

  const handleOpenDialog = () => {
    track("Trip Document Opened", {
      eventId: "trip-document-opened",
      documentTitle: title,
    });
    setIsDialogOpen(true);
  };

  return (
    <div className={styles.container}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img alt="pdf" src="/images/pdf.svg" className={styles.pdfImage} />
      <div className={styles.content}>
        <Typography variant="body1" className={styles.title}>
          {title}
        </Typography>
        <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess}>
          <Typography variant="body2" color="text.secondary">
            PDF &nbsp;| {numPages} page
          </Typography>
        </Document>
        <Button
          endIcon={<ArrowIcon dir="right" />}
          className={styles.openButton}
          variant="text"
          onClick={handleOpenDialog}
        >
          Open
        </Button>
      </div>
      <Button
        endIcon={<DownloadIcon />}
        onClick={handleOpenDialog}
        className={styles.button}
        variant="text"
      >
        Preview & Download
      </Button>
      {isDialogOpen && (
        <GeneralPdfViewer
          onClose={() => setIsDialogOpen(false)}
          pdfUrl={pdfLink || ""}
          title={title || ""}
        />
      )}
    </div>
  );
};

export default FileItem;
